import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Settings from "src/stores/Settings";
import PageContext from "src/stores/Page";

import Link, { TYPES } from "src/atoms/Link";
import { colors, spacing } from "src/styles/variables";
import { HEADER_HEIGHT, HOME_LINK, BEZIER } from "./constants";
import NormalMenu from "./Menu";
import Logo from "./Logo";

const isMaintenance = process.env.GATSBY_MAINTENANCE === "true";

const Menu = React.memo(({ green, opened, setOpened, setWillClose }) => {
  const { headerLinks } = useContext(Settings);

  const {
    headerHeight: [, setHeaderHeight],
  } = useContext(PageContext);

  useEffect(() => {
    setHeaderHeight(HEADER_HEIGHT.desktop);
    if (opened) {
      setOpened(false);
      setWillClose(false);
    }
  }, []);

  if (headerLinks.length === 3) {
    headerLinks.unshift(HOME_LINK);
  }

  if (isMaintenance) {
    return (
      <NormalMenu
        green={green}
        opened={opened}
        setOpened={setOpened}
        setWillClose={setWillClose}
      />
    );
  }

  return (
    <Contained transparent={!green}>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <DesktopLinks>
        {headerLinks.map((headerLink, index) => (
          <LinkStyled
            activeClassName="active"
            key={index}
            {...headerLink}
            type={TYPES.navigation}
          />
        ))}
      </DesktopLinks>
    </Contained>
  );
});

Menu.propTypes = {
  green: PropTypes.bool,
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
  setWillClose: PropTypes.func,
};

const Contained = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  padding-left: 70px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      to top,
      ${colors.activiaGradient[0]} 0%,
      ${colors.activiaGradient[1]} 100%
    );
    opacity: ${({ transparent }) => (transparent ? "0" : "1")};
    transition: opacity 300ms ${BEZIER} 300ms;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${colors.activiaGreen};
    opacity: ${({ transparent }) => (transparent ? "0" : "1")};
    transition: opacity 300ms ${BEZIER} 300ms;
  }
`;

const DesktopLinks = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${colors.white};
  padding-left: 40px;
`;

/* Common components */
const LinkStyled = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  line-height: 1.15;

  height: 100%;
  display: flex;
  align-items: center;
  margin-right: ${spacing.default.xl};

  &.active {
    color: ${colors.white};
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 4px;
      background: ${colors.white};
    }
  }
`;

const LogoContainer = styled.div`
  opacity: 1;
  margin-top: 25px;
`;

export default Menu;
