import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ClipboardJS from "clipboard";

import Settings from "src/stores/Settings";

import { icons, spacing, colors, mediaquery } from "src/styles/variables";

import { HeaderM } from "src/atoms/Typography";

import Facebook from "src/atoms/Vectors/Social/Facebook";
import Twitter from "src/atoms/Vectors/Social/Twitter";
import CopyUrl from "src/atoms/Vectors/Social/CopyUrl";
import Whatsapp from "src/atoms/Vectors/Social/Whatsapp";
import Email from "src/atoms/Vectors/Social/Email";

import Bubble, { ARROW_POSITION } from "src/atoms/Bubble";
import trackEvent from "./trackEvent";

const POPUP_SIZE = 570;

const Share = ({
  title,
  enableFacebook = true,
  enableTwitter = true,
  enableCopy = true,
  enableWhatsapp = true,
  enableEmail = true,
  twitterCopy,
  url,
}) => {
  const [bubbleOpened, setBubbleOpened] = useState(false);
  const [documentURL, setDocumentURL] = useState("");
  const copyToClipboardButton = useRef(null);

  const pageUrl = encodeURIComponent(documentURL);
  // const pageTitle = encodeURIComponent(document.title);
  const pageTitle = useContext(Settings).seo.title;

  const socialWindow = (url) => {
    const left = (screen.width - POPUP_SIZE) / 2;
    const top = (screen.height - POPUP_SIZE) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
    window.open(url, "NewWindow", params);
  };

  useEffect(() => {
    setDocumentURL(document.URL);
  }, []);

  useEffect(() => {
    if (copyToClipboardButton.current) {
      const clipboard = new ClipboardJS(copyToClipboardButton.current);

      clipboard.on("success", () => {
        trackEvent("link");
        setBubbleOpened(true);
      });
    }
  }, []);

  return (
    <Settings.Consumer>
      {(settings) => (
        <>
          {!!title && <Header>{title}</Header>}
          <ShareContainer>
            {!!enableFacebook && (
              <ShareItem>
                <Button
                  onClick={() => {
                    trackEvent("facebook");
                    socialWindow(
                      `https://www.facebook.com/sharer/sharer.php?u=${
                        url ? url : pageUrl
                      }`
                    );
                  }}
                >
                  <Facebook size={icons.s} fill={colors.white} />
                </Button>
              </ShareItem>
            )}
            {!!enableTwitter && (
              <ShareItem>
                <Button
                  onClick={() => {
                    trackEvent("twitter");
                    socialWindow(
                      `https://twitter.com/intent/tweet?url=${
                        url ? url : pageUrl
                      }&text=${twitterCopy || ""}`
                    );
                  }}
                >
                  <Twitter size={icons.s} fill={colors.white} />
                </Button>
              </ShareItem>
            )}
            {!!enableWhatsapp && (
              <ShareItem>
                <Button
                  onClick={() => {
                    trackEvent("whatsapp");
                    socialWindow(`https://wa.me/?text=${url ? url : pageUrl}`);
                  }}
                  data-action="share/whatsapp/share"
                >
                  <Whatsapp stroke={colors.white} size={icons.xs} />
                </Button>
              </ShareItem>
            )}
            {!!enableEmail && (
              <ShareItem>
                <Link
                  href={`mailto:?subject=${pageTitle}&body=${
                    url ? url : pageUrl
                  }`}
                >
                  <Email stroke={colors.white} size={icons.xs} />
                </Link>
              </ShareItem>
            )}
            {!!enableCopy && (
              <ShareItem style={{ position: "relative" }}>
                <StyledBubble
                  onClose={() => setBubbleOpened(false)}
                  isOpen={bubbleOpened}
                  forcePosition={ARROW_POSITION.center}
                  title={settings.translations.shareUrlPopup}
                  data-nosnippet
                />
                <Button
                  ref={copyToClipboardButton}
                  data-clipboard-text={url ? url : documentURL}
                >
                  <CopyUrl stroke={colors.white} size={icons.xs} />
                </Button>
              </ShareItem>
            )}
          </ShareContainer>
        </>
      )}
    </Settings.Consumer>
  );
};

const ShareContainer = styled.ul`
  display: flex;
`;

const ShareItem = styled.li`
  margin-left: ${spacing.default.sm};
  background: ${colors.activiaGreen};
  border-radius: 50%;
  width: ${icons.m};
  height: ${icons.m};
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }
`;

const Button = styled.button`
  outline: none;
  cursor: pointer;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Link = styled.a`
  color: ${colors.white};
  text-decoration: none;
  line-height: 1.15;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing.default.sm};
`;

const StyledBubble = styled(Bubble)`
  position: absolute;
  bottom: 50px;
  width: 260px;
  left: 50%;
  transform: translateX(-50%);

  & > div {
    &:before {
      content: "${({ title }) => title}";
    }
  }
`;

const Header = styled(HeaderM)`
  display: inline-block;
  margin-bottom: 0.5rem;

  ${mediaquery.md(css`
    margin-bottom: 1rem;
  `)}
`;

Share.propTypes = {
  title: PropTypes.string,
  enableFacebook: PropTypes.bool,
  enableTwitter: PropTypes.bool,
  enableCopy: PropTypes.bool,
  enableWhatsapp: PropTypes.bool,
  enableEmail: PropTypes.bool,
  twitterCopy: PropTypes.string,
  url: PropTypes.string,
};

export default Share;
