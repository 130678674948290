import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import Settings from "src/stores/Settings";
import Button from "src/atoms/Button";
import LinkPill from "src/atoms/LinkPill";
import LinkImage from "src/atoms/LinkImage";
import {
  NavigationText,
  LinkText,
  HeaderM,
  HeaderS,
} from "src/atoms/Typography";
import getLocalizedSlug from "src/utils/getLocalizedSlug";
import trackEvent from "./trackEvent";

export const TYPES = {
  button: "button",
  linkPill: "linkPill",
  navigation: "navigation",
  link: "link",
  linkImage: "linkImage",
  headerMobile: "headerMobile",
  headerMobileSmall: "headerMobileSmall",
};

const Link = React.memo(
  ({
    label,
    buttonStyle = undefined,
    icon,
    target = undefined,
    url,
    title,
    image,
    type,
    clickScript,
    className,
    activeClassName,
    children = undefined,
    mediaToDownload,
    usePopup,
    popupId,
    closePopup,
  }) => {
    const { locale } = useContext(Settings);

    let Component;
    switch (type) {
      case TYPES.button:
        Component = Button;
        break;
      case TYPES.linkPill:
        Component = LinkPill;
        break;
      case TYPES.linkImage:
        Component = LinkImage;
        break;
      case TYPES.navigation:
        Component = NavigationText;
        break;
      case TYPES.headerMobile:
        Component = HeaderM;
        break;
      case TYPES.headerMobileSmall:
        Component = HeaderS;
        break;
      case TYPES.link:
      default:
        Component = LinkText;
    }

    let navProps;
    if (url?.startsWith("/") || usePopup) {
      navProps = {
        to: getLocalizedSlug(url, locale),
        as: GatsbyLink,
      };
    } else {
      if (mediaToDownload?.file?.url) {
        url = `${mediaToDownload?.file?.url}?dl=${mediaToDownload?.file?.fileName}`;
      }
      navProps = { href: url, as: "a", rel: "noopener" };
    }

    const handleScript = (e) => {
      e.preventDefault();
      try {
        // eslint-disable-next-line no-new-func
        new Function(clickScript)();
      } catch (err) {
        console.log(err);
      }
    };

    const handleClick = (e) => {
      if (type === TYPES.button) {
        trackEvent(title);
      }
      if (closePopup) {
        document.getElementById(popupId).classList.remove("active");
        document
          .getElementsByTagName("body")[0]
          .classList.remove("overflow-hidden");
      }
      if (usePopup) {
        e.preventDefault();

        document
          .getElementsByTagName("body")[0]
          .classList.add("overflow-hidden");
        document.getElementById(popupId).classList.add("active");
      } else if (clickScript) {
        handleScript(e);
      }
    };

    return (
      <Component
        className={className}
        title={title}
        image={image?.fluid || undefined}
        buttonstyle={buttonStyle || "Dark"}
        icon={icon || undefined}
        url={url || ""}
        target={target || "_self"}
        {...navProps}
        activeClassName={activeClassName}
        onClick={handleClick}
        download={!!mediaToDownload && mediaToDownload?.file?.fileName}
      >
        {children || label}
      </Component>
    );
  }
);

export const linkPropTypes = {
  label: PropTypes.string,
  target: PropTypes.string,
  buttonStyle: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  clickScript: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  usePopup: PropTypes.bool,
  popupId: PropTypes.string,
  closePopup: PropTypes.bool,
};

Link.propTypes = {
  ...linkPropTypes,
  className: PropTypes.string,
};

export default Link;
