import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import { LatestCardsStore } from "src/stores/LatestCards";

import Layout from "src/layouts/Main";
import SEO from "src/atoms/SEO";
import PageComponentList from "src/organisms/PageComponentList";
import ScreenReaderTitle from "src/atoms/ScreenReaderTitle";
import mergeSansNullOrUndefined from "src/utils/mergeSansNullOrUndefined";
import mappers from "src/organisms/PageComponentList/mappers";
import getMetaImageFields from "src/utils/getMetaImageFields";
import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import breadcrumb from "src/utils/breadcrumb";
import trackEvent from "./trackEvent";

export const HCP_COOKIE_NAME = "hcp_accepted";

// Create hcp cookie and set to false by default
export const setCookie = (name, value) => {
  const date = new Date();
  date.setTime(date.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;secure;samesite=strict`;
};

// Function to get cookie value
const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=").map((c) => c.trim());
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};

const GenericPage = ({
  data,
  pageContext: { locale, localizedSlugs, hreflang },
  path,
}) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }

  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };

  let currentUrl;

  useEffect(() => {
    currentUrl = window.location.href;
  }, []);

  // Set initial cookie
  useEffect(() => {
    if (!getCookie(HCP_COOKIE_NAME)) {
      setCookie(HCP_COOKIE_NAME, "false");
    }
  }, []);

  // Check for all sub slugs of the hcpParentSlug and show the popup for these as well
  const isSubSlugOfHcpParent = () => {
    const hcpParentSlug = settings.hcpParentSlug;
    const currentPath = window.location.pathname;
    return currentPath.startsWith(hcpParentSlug);
  };

  // Render the HCP popup on all sub slugs of the hcpParentSlug
  useEffect(() => {
    if (currentUrl.includes(settings.hcpParentSlug) && isSubSlugOfHcpParent()) {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
      document
        .getElementById(settings.hcpPopup?.popupId)
        ?.classList.add("active");
    }
  }, [currentUrl, settings.hcpParentSlug, settings.hcpPopup?.popupId]);

  const page = data.contentfulPage;

  // Render the HCP popup if the hcp_accepted cookie is false
  useEffect(() => {
    const cookieValue = getCookie(HCP_COOKIE_NAME);

    if (
      cookieValue === "false" &&
      currentUrl.includes(settings.hcpParentSlug)
    ) {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
      document
        .getElementById(settings.hcpPopup?.popupId)
        ?.classList.add("active");
    }
  }, [
    settings.hcpParentSlug,
    isSubSlugOfHcpParent,
    settings.hcpPopup?.popupId,
  ]);

  const hero =
    (page &&
      page.components.find((item) => item.__typename === "ContentfulHero")) ||
    {};
  const hasHero = hero.__typename && hero.__typename === "ContentfulHero";
  const heroHasTitle = hasHero && hero.title;

  const isFAQPage =
    page && page.components.length !== 0 && page.useFaqData !== null;
  !!page.useFaqData &&
    page.components.some(
      (item) => item.__typename === "ContentfulQuestionAnswerList"
    );

  const fullUrl = `${locale.currentRegion.url}${path}`;
  const jsonld = [breadcrumb([{ name: page.slug, url: fullUrl }])];

  if (isFAQPage) {
    jsonld.push({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: mappers["ContentfulQuestionAnswerList"]
        .mapper(
          page.components.find(
            (item) => item.__typename === "ContentfulQuestionAnswerList"
          )
        )
        .items.map(({ question, answer }) => ({
          "@type": "Question",
          name: question,
          acceptedAnswer: {
            "@type": "Answer",
            text: documentToPlainTextString(JSON.parse(answer.raw)),
          },
        })),
    });
  }

  const pageMetadata = {
    ...page.seo,
    image:
      page.seo.image && getMetaImageFields(page.seo.image, PICTURE_TYPES.fixed),
  };

  const meta = mergeSansNullOrUndefined(
    {
      image:
        hasHero && getMetaImageFields(hero.media.small, PICTURE_TYPES.fluid),
    },
    pageMetadata
  );

  useEffect(() => {
    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: "Page",
      page_name: page.seo.title,
      page_type: "Generic Page",
      page_subcategory: "",
      page: path,
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC?.container?.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
    //console.log('window.tc_vars generic page',window.tc_vars);
  }, []);

  return (
    <SettingsStore value={settings}>
      <SEO
        path={path}
        meta={meta}
        jsonld={jsonld}
        hreflang={hreflang}
        localizedSlugs={localizedSlugs}
      />
      <LatestCardsStore {...data}>
        <PageStore>
          <Layout locale={locale}>
            {(!hasHero || !heroHasTitle) && (
              <ScreenReaderTitle>{meta.title}</ScreenReaderTitle>
            )}
            <PageComponentList modules={page.components} />
          </Layout>
        </PageStore>
      </LatestCardsStore>
    </SettingsStore>
  );
};

GenericPage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulPage: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      seo: PropTypes.object.isRequired,
      components: PropTypes.array.isRequired,
      useFaqData: PropTypes.boolean,
    }).isRequired,
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
    hreflang: PropTypes.object,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const pageQuery = graphql`
  query getContentfulPage($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }
    contentfulPage(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...GenericPage
    }

    latestEditorialPhotography: allContentfulEditorialPhotographyWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialPhotographyCard
        updatedAt
      }
    }

    latestEditorialIllustration: allContentfulEditorialIllustrationWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialIllustrationCard
        updatedAt
      }
    }

    latestInfluencer: allContentfulInfluencerWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...InfluencerCard
        updatedAt
      }
    }

    latestRecipe: allContentfulRecipeWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...RecipeCard
        updatedAt
      }
    }

    latestFlavor: allContentfulProductFlavorWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...FlavorCard
        updatedAt
      }
    }
  }
`;

export default GenericPage;
