import { React } from "react";
import Popup from "./Popup";
import Spacer from "src/organisms/PageComponentList/Spacer";

const PageComponent = (props) => {
  return (
    <>
      <Popup {...props} />
      <Spacer />
    </>
  );
};

export default PageComponent;
